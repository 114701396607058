import React, { useState, useEffect } from 'react'
import {
	Switch,
	Route,
	Redirect,
	useLocation,
	useHistory,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { Toaster } from 'react-hot-toast'

import Spinner from '../components/Spinner/Spinner'
import Error from '../components/Error/Error'

// theCalendar
import Promotion from '../components/Promotion/Promotion'
import Login from '../pages/Login/Login'
import Event from '../pages/Event/Event'

import Header from '../components/Header/Header'

import Main from '../pages/Main/Main'
import About from '../pages/About/About'
import Rates from '../pages/Rates/Rates'
import Loyalty from '../pages/Loyalty/Loyalty'
import Guide from '../pages/Guide/Guide'
import Information from '../pages/Information/Information'
import Business from '../pages/Business/Business'
import Legal from '../pages/Legal/Legal'
import { News, NewsPage } from '../pages/News'
import MobileArticle from '../pages/MobileArticle/MobileArticle'

import Footer from '../components/Footer/Footer'
import Cookie from '../components/Cookie/Cookie'

import Page from '../components/Page/Page'

import WithService from '../services/hoc'
import { useDispatch, useSelector } from 'react-redux'
import {
	carsRequested,
	diffRequested,
	mapMarkersRequested,
	mapPolygonsRequested,
	bonuses,
	dataLoaded,
	dataError,
	handleLanguage,
} from '../redux/actions'
import { useCookie } from '../utils/cookie'


const eventImgFolder = '12.2024'
const eventImgType = 'webp'

const calendarDate = {
	start: ['01', '12', '2024'],
	end: ['24', '12', '2024'],
}

const isSnowfall = true
const isFlip = false

const
	imgNumber = Math.floor((
		new Date().getTime()
		- new Date(`${calendarDate.start[1]}/${calendarDate.start[0]}/${calendarDate.start[2]}`).getTime()
	) / (1000 * 3600 * 24)) + 1,
	calendarFullDate = {
		start: '' + calendarDate.start[2] + calendarDate.start[1] + calendarDate.start[0],
		end: '' + calendarDate.end[2] + calendarDate.end[1] + calendarDate.end[0],
	},
	nowFullDate = '' +
		new Date().getFullYear() +
		(new Date().getMonth() + 1 <= 9
			? '0' + (new Date().getMonth() + 1)
			: new Date().getMonth() + 1) +
		(new Date().getDate() <= 9
			? '0' + new Date().getDate()
			: new Date().getDate())


const App = props => {
	const { i18n, t } = useTranslation()

	const loading = useSelector(state => state.loading)
	const error = useSelector(state => state.error)
	const language = useSelector(state => state.language)
	const hasCookies = useSelector(state => state.cookies)

	const dispatch = useDispatch()
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const history = useHistory()

	const [previousPath, setPreviousPath] = useState(null)

	useEffect(() => {
		if (previousPath === null) setPreviousPath(location.pathname)

		TagManager.dataLayer({
			"event": "page_view",
			"page_location": `https://carguru.lv/${location.pathname}`,
			"page_referrer": `https://carguru.lv/${previousPath || ''}`,
			"page_title": `${document.title}`
		})

		setPreviousPath(location.pathname)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname])

	useEffect(() => {
		let isFramed = false
		try {
			// eslint-disable-next-line no-restricted-globals
			isFramed = window !== window.top || document !== top.document || self.location !== top.location
		} catch (e) {
			isFramed = true
		}

		if (isFramed && location.pathname.split('/')[1] !== 'mobile-article') {
			document.body.style.display = 'none'
			document.body.style.opacity = '0'
			document.body.style.overflow = 'hidden'
			document.body.style.pointerEvents = 'none'
			document.body.removeChild(document.getElementById('root'))

			const alertText = t("xss.alert") !== 'xss.alert' ? t("xss.alert") : 'Warning! You are not on our official site. Please visit our official website: https://carguru.lv'

			alert(alertText)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])



	useEffect(() => {
		if (hasCookies) ReactGA.pageview(location.pathname + location.search)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, hasCookies])

	useEffect(() => {
		if (hasCookies) ReactGA.initialize('UA-131150222-1')
		else useCookie.deleteAll()

		// /mobile-article
		if (location.pathname.split('/')[1] === 'mobile-article') {
			if (queryParams.get('theme') === 'dark')
				document.body.style.backgroundColor = '#292F35'
			dispatch(dataLoaded())
		} else {
			const { Service } = props
			Promise.all([
				Service.getCars(),
				Service.getDiff(),
				Service.getMapCars(),
				Service.getMapGeo(),
			])
				.then(([cars, diff, mapCars, mapGeo]) => {
					dispatch(carsRequested(cars))
					dispatch(diffRequested(diff))
					dispatch(mapMarkersRequested(mapCars))
					dispatch(mapPolygonsRequested(mapGeo))
				})
				.then(() => {
					if (queryParams.get('lang')) {
						if (
							queryParams.get('lang') === 'ru' ||
							queryParams.get('lang') === 'en' ||
							queryParams.get('lang') === 'lv'
						) {
							dispatch(handleLanguage(queryParams.get('lang')))
							i18n.changeLanguage(queryParams.get('lang'))
						}
						queryParams.delete('lang')
						history.replace({ search: queryParams.toString() })
					} else {
						if (hasCookies)
							i18n.changeLanguage(localStorage.getItem('i18nextLng'))
					}
				})
				.then(() => dispatch(dataLoaded()))
				.catch(error => {
					console.error(error)
					dispatch(dataError())
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const { Service } = props

		if (location.pathname.split('/')[1] !== 'mobile-article') {
			if (hasCookies) {
				if (localStorage.getItem('i18nextLng'))
					Service.getBonuses(localStorage.getItem('i18nextLng')).then(
						({ result }) => dispatch(bonuses(result))
					)
			} else {
				Service.getBonuses(language).then(({ result }) =>
					dispatch(bonuses(result))
				)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasCookies && localStorage.getItem('i18nextLng'), language])

	if (error) return <Error />

	if (loading) return <Spinner />

	return (
		<div className='App'>
			<Helmet
				htmlAttributes={{
					lang: (hasCookies && localStorage.getItem('i18nextLng')) || language,
				}}
			>
				<meta property='og:type' content='article' />
				<meta property='og:title' content='CARGURU' />
				<meta
					property='og:description'
					content='Carguru is the first car sharing service in Latvia. All costs, including petrol, car parks and insurance, are included in the fee.'
				/>
				<meta
					property='og:image'
					content='%PUBLIC_URL%/favicon/android-chrome-256x256.png'
				/>
			</Helmet>

			<Toaster />

			<Switch>
				<Route path='/' exact>
					{/* theCalendar */}
					{nowFullDate >= calendarFullDate.start &&
						nowFullDate <= calendarFullDate.end && <Promotion />}

					<Header />
				</Route>

				<Route
					path={[
						'/loyalty',
						'/guide',
						'/faq',
						'/article',
						'/about',
						'/rates',
						'/business',
						'/news',
						'/legal',
						'/login',
						'/event',
					]}
				>
					{/* theCalendar */}
					{nowFullDate >= calendarFullDate.start &&
						nowFullDate <= calendarFullDate.end && <Promotion />}

					<Header />
				</Route>
			</Switch>

			<Switch>
				<Route path='/' exact >
					<Main />
				</Route>

				<Route path='/about' exact>
					<Page>
						<About />
					</Page>
				</Route>

				<Route path='/rates' exact>
					<Page>
						<Rates />
					</Page>
				</Route>

				<Route path='/loyalty'>
					<Loyalty />
				</Route>

				<Route path='/guide'>
					<Page>
						<Guide />
					</Page>
				</Route>

				<Route path='/faq'>
					<Page>
						<Information />
					</Page>
				</Route>

				<Route path='/business' exact>
					<Business />
				</Route>

				<Route path='/news' exact>
					<Page>
						<News />
					</Page>
				</Route>

				<Route path='/article'>
					<Page>
						<NewsPage />
					</Page>
				</Route>

				<Route path='/mobile-article'>
					<MobileArticle />
				</Route>

				<Route path='/legal'>
					<Page>
						<Legal />
					</Page>
				</Route>

				{/* theCalendar */}
				{nowFullDate >= calendarFullDate.start &&
					nowFullDate <= calendarFullDate.end && (
						<Route path='/login' exact>
							<Login />
						</Route>
					)}
				{nowFullDate >= calendarFullDate.start &&
					nowFullDate <= calendarFullDate.end && (
						<Route path='/event' exact>
							<Event
								imgNumber={imgNumber}
								folder={eventImgFolder}
								imgType={eventImgType}
								calendarDate={calendarDate}
								isSnowfall={isSnowfall}
								isFlip={isFlip}
							/>
						</Route>
					)}

				<Route path='*' render={() => <Redirect to='/' />} />
			</Switch>

			<Switch>
				<Route path='/' exact>
					<Footer />
					{hasCookies && <Cookie />}
				</Route>

				<Route
					path={[
						'/loyalty',
						'/guide',
						'/faq',
						'/article',
						'/about',
						'/rates',
						'/business',
						'/news',
						'/legal',
						'/login',
						'/event',
					]}
				>
					<Footer />
					{hasCookies && <Cookie />}
				</Route>
			</Switch>
		</div>
	)
}

export default WithService()(App)
